::-webkit-scrollbar {
    width: 0.6rem;
}

::-webkit-scrollbar-track {
    background: transparent;
    border-right: 1px solid #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #e0e0e0;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #ccc;
}

.vcc-invalid {
    color: orangered;
}


@media only screen and (min-width: 600px) {
    :root {
        --amplify-text-md-sub: 1.74rem;
        --amplify-text-sm: 1.3rem;
        --amplify-text-xs: 1.196rem;
    }

    amplify-authenticator {
        --min-width: 480px;
    }
}
